export const LinkedInSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>linkedin</title>
    <path d="M6 3.6c0 1.274-0.852 2.371-2.401 2.371-1.458 0-2.399-1.034-2.399-2.309 0-1.308 0.912-2.462 2.4-2.462s2.371 1.092 2.4 2.4zM1.2 22.8v-15.6h4.8v15.6h-4.8z"></path>
    <path d="M8.4 12.533c0-1.854-0.061-3.403-0.122-4.741h4.313l0.214 2.068h0.091c0.607-0.973 2.095-2.4 4.586-2.4 3.038 0 5.318 2.034 5.318 6.41v8.93h-4.8v-8.233c0-1.913-0.728-3.372-2.4-3.372-1.274 0-1.913 1.032-2.248 1.883-0.122 0.305-0.152 0.73-0.152 1.156v8.567h-4.8v-10.267z"></path>
  </svg>
);
