export const XSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>x</title>
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24h-6.657l-5.214-6.817-5.966 6.817h-3.31l7.73-8.835-8.156-10.665h6.826l4.713 6.231zM17.083 19.77h1.833l-11.832-15.644h-1.967z"></path>
  </svg>
);
