export const YouTubeSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>youtube</title>
    <path d="M12 2.76c-11.794 0-12 1.049-12 9.24s0.206 9.24 12 9.24 12-1.049 12-9.24-0.206-9.24-12-9.24zM15.846 12.401l-5.388 2.515c-0.472 0.218-0.858-0.026-0.858-0.547v-4.738c0-0.52 0.386-0.766 0.858-0.547l5.388 2.515c0.472 0.221 0.472 0.581 0 0.802z"></path>
  </svg>
);
