export const TikTokSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>tiktok</title>
    <path d="M12.53 0.020c1.31-0.020 2.61-0.010 3.91-0.020 0.080 1.53 0.63 3.090 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.030c-1.44-0.050-2.89-0.35-4.2-0.97-0.57-0.26-1.1-0.59-1.62-0.93-0.010 2.92 0.010 5.84-0.020 8.75-0.080 1.4-0.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43 0.080-2.86-0.31-4.080-1.030-2.020-1.19-3.44-3.37-3.65-5.71-0.020-0.5-0.030-1-0.010-1.49 0.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72 0.020 1.48-0.040 2.96-0.040 4.44-0.99-0.32-2.15-0.23-3.020 0.37-0.63 0.41-1.11 1.040-1.36 1.75-0.21 0.51-0.15 1.070-0.14 1.61 0.24 1.64 1.82 3.020 3.5 2.87 1.12-0.010 2.19-0.66 2.77-1.61 0.19-0.33 0.4-0.67 0.41-1.060 0.1-1.79 0.060-3.57 0.070-5.36 0.010-4.030-0.010-8.050 0.020-12.070z"></path>
  </svg>
);
