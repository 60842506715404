export const WhatsAppSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    width="95"
    height="95"
    viewBox="0 0 95 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-label={ariaLabel}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17 70.4916L0 95L26.6 88.0011C33.0878 91.4178 40.4749 93.3621 48.319 93.3621C74.1 93.3621 95 72.4621 95 46.681C95 20.9 74.1 0 48.319 0C22.5379 0 1.63793 20.9 1.63793 46.681C1.63793 55.3817 4.0244 63.519 8.17 70.4916ZM67.9969 53.485C67.9969 53.485 75.9458 58.1433 78.1013 62.2168C79.0186 63.9481 78.6992 66.0758 77.3135 67.4647L74.434 70.3442C65.2993 79.479 54.9868 74.9812 45.8521 65.8465L37.5036 57.4979L29.155 49.1494C20.0203 40.0146 15.5225 29.7022 24.6573 20.5675L27.5368 17.688C28.9224 16.3023 31.0501 15.9813 32.7831 16.8985C36.8566 19.054 41.5149 27.0029 41.5149 27.0029C42.5402 28.8014 42.6549 30.97 40.8269 31.997C40.8269 31.997 37.7296 34.58 37.2841 35.2368C35.2514 38.2375 35.33 41.0171 38.1358 43.8228L44.6564 50.3434L51.177 56.864C53.9828 59.6698 56.7624 59.7484 59.7631 57.7158C60.4199 57.2702 63.0029 54.1729 63.0029 54.1729C64.0282 52.345 66.1985 52.4596 67.9969 53.485Z"
    />
  </svg>
);
