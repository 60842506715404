export const InstagramSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>instagram</title>
    <path d="M12 2.161c3.206 0 3.586 0.014 4.847 0.070 1.172 0.052 1.805 0.248 2.227 0.413 0.558 0.216 0.961 0.478 1.378 0.895 0.422 0.422 0.68 0.82 0.9 1.378 0.164 0.422 0.361 1.059 0.413 2.227 0.056 1.266 0.070 1.645 0.070 4.847s-0.014 3.586-0.070 4.847c-0.052 1.172-0.248 1.805-0.413 2.227-0.216 0.558-0.478 0.961-0.895 1.378-0.422 0.422-0.82 0.68-1.378 0.9-0.422 0.164-1.059 0.361-2.227 0.413-1.266 0.056-1.645 0.070-4.847 0.070s-3.586-0.014-4.847-0.070c-1.172-0.052-1.805-0.248-2.227-0.413-0.558-0.216-0.961-0.478-1.378-0.895-0.422-0.422-0.68-0.82-0.9-1.378-0.164-0.422-0.361-1.059-0.412-2.227-0.056-1.266-0.070-1.645-0.070-4.847s0.014-3.586 0.070-4.847c0.052-1.172 0.248-1.805 0.412-2.227 0.216-0.558 0.478-0.961 0.895-1.378 0.422-0.422 0.82-0.68 1.378-0.9 0.422-0.164 1.059-0.361 2.227-0.413 1.261-0.056 1.641-0.070 4.847-0.070zM12 0c-3.258 0-3.666 0.014-4.945 0.070-1.275 0.056-2.152 0.262-2.911 0.558-0.792 0.309-1.463 0.717-2.128 1.387-0.67 0.666-1.078 1.336-1.388 2.123-0.295 0.764-0.502 1.636-0.558 2.911-0.056 1.284-0.070 1.692-0.070 4.95s0.014 3.666 0.070 4.945c0.056 1.275 0.262 2.152 0.558 2.911 0.309 0.792 0.717 1.462 1.388 2.128 0.666 0.666 1.336 1.078 2.123 1.383 0.764 0.295 1.636 0.502 2.911 0.558 1.28 0.056 1.688 0.070 4.945 0.070s3.666-0.014 4.945-0.070c1.275-0.056 2.152-0.262 2.911-0.558 0.788-0.305 1.458-0.717 2.123-1.383s1.078-1.336 1.383-2.123c0.295-0.764 0.502-1.636 0.558-2.911 0.056-1.28 0.070-1.688 0.070-4.945s-0.014-3.666-0.070-4.945c-0.056-1.275-0.262-2.152-0.558-2.911-0.295-0.797-0.703-1.467-1.373-2.133-0.666-0.666-1.336-1.078-2.123-1.383-0.764-0.295-1.636-0.502-2.911-0.558-1.284-0.061-1.692-0.075-4.95-0.075v0z"></path>
    <path d="M12 5.836c-3.403 0-6.164 2.761-6.164 6.164s2.761 6.164 6.164 6.164 6.164-2.761 6.164-6.164c0-3.403-2.761-6.164-6.164-6.164zM12 15.998c-2.208 0-3.998-1.791-3.998-3.998s1.791-3.998 3.998-3.998c2.208 0 3.998 1.791 3.998 3.998s-1.791 3.998-3.998 3.998z"></path>
    <path d="M19.847 5.592c0 0.795-0.644 1.439-1.439 1.439s-1.439-0.644-1.439-1.439c0-0.795 0.644-1.439 1.439-1.439s1.439 0.644 1.439 1.439z"></path>
  </svg>
);
