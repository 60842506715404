export const FacebookSVG = ({
  className,
  ariaLabel,
}: {
  className?: string;
  ariaLabel?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 24"
    className={className}
    aria-label={ariaLabel}
  >
    <title>facebook</title>
    <path d="M12.844 0.161v3.536h-2.103c-1.647 0-1.955 0.79-1.955 1.929v2.531h3.924l-0.522 3.964h-3.402v10.165h-4.098v-10.165h-3.415v-3.964h3.415v-2.92c0-3.388 2.076-5.237 5.103-5.237 1.446 0 2.692 0.107 3.054 0.161z"></path>
  </svg>
);
