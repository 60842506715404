export enum SocialMediaEnum {
  INSTAGRAM = 'Instagram',
  X = 'X',
  YOUTUBE = 'YouTube',
  TIKTOK = 'TikTok',
  FACEBOOK = 'Facebook',
  LINKEDIN = 'LinkedIn',
  WHATSAPP = 'WhatsApp',
  SNAPCHAT = 'SnapChat',
  DISCORD = 'Discord',
}

export enum SocialPlatformHostEnum {
  INSTAGRAM = 'https://www.instagram.com/',
  X = 'https://www.x.com/',
  YOUTUBE = 'https://www.youtube.com/',
  TIKTOK = 'https://www.tiktok.com/@',
  FACEBOOK = 'https://www.facebook.com/',
  LINKEDIN = 'https://www.linkedin.com/in/',
  WHATSAPP = 'https://wa.me/',
  SNAPCHAT = 'https://www.snapchat.com/',
  DISCORD = 'https://www.discord.com/invite/',
}

export enum SocialPlatformShortHostEnum {
  INSTAGRAM = 'instagram.com/',
  X = 'x.com/',
  YOUTUBE = 'youtube.com/',
  TIKTOK = 'tiktok.com/@',
  FACEBOOK = 'facebook.com/',
  LINKEDIN = 'linkedin.com/in/',
  WHATSAPP = 'wa.me/',
  SNAPCHAT = 'snapchat.com/',
  DISCORD = 'discord.com/invite/',
}

export const socialPlatformHostForValue = (value: string) =>
  SocialPlatformHostEnum[
    value as keyof typeof SocialPlatformHostEnum
  ] as string;

export const socialPlatformShortHostForValue = (value: string) =>
  SocialPlatformShortHostEnum[
    value as keyof typeof SocialPlatformShortHostEnum
  ] as string;

export enum CalloutType {
  BOOK_MY_TIME = 'bookMyTime',
  FLEXIBLE_PAYMENTS = 'flexiblePayments',
  ASSISTANT = 'assistant',
}
