import { DiscordSVG } from './DiscordSVG';
import { FacebookSVG } from './FacebookSVG';
import { InstagramSVG } from './InstagramSVG';
import { LinkedInSVG } from './LinkedInSVG';
import { SnapChatSVG } from './SnapChatSVG';
import { TikTokSVG } from './TikTokSVG';
import { WhatsAppSVG } from './WhatsAppSVG';
import { XSVG } from './XSVG';
import { YouTubeSVG } from './YouTubeSVG';

export function PlatformIcon({
  className,
  iconName,
  ariaLabel,
}: {
  className?: string;
  iconName: string;
  ariaLabel?: string;
}) {
  const SVGComponent = {
    DiscordSVG,
    FacebookSVG,
    InstagramSVG,
    LinkedInSVG,
    SnapChatSVG,
    TikTokSVG,
    WhatsAppSVG,
    XSVG,
    YouTubeSVG,
  }[iconName];

  if (!SVGComponent) {
    return <></>;
  }
  return <SVGComponent className={className} ariaLabel={ariaLabel} />;
}
